import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';

import { makeStyles, Theme } from '@material-ui/core/styles';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import DescriptionIcon from '@material-ui/icons/Description';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import NoteIcon from '@material-ui/icons/Note';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Button from '@material-ui/core/Button';
import ReactLoading from 'react-loading';

import * as common from "../common";
import { Layout } from "./Layout";
import { APIResponse, Exam, ExamQuestions, learner } from "../react-app-env";
import { QrCode } from './QrCode';
import { DefaultButton } from './StylesUI/CommonLayouts';
import { StartScreen } from './CommonConponents/StartScreen';
import { CustomModal } from "./StylesUI/CustomModal";
import ReactPlayer from 'react-player';
import { debug } from 'console';
import { ExtCode } from '../common';
import { sendSync, useSync } from '../sync';

// 試験開始状況
enum ExamStartStatus {
  Before = 0,
  InProcess = 1,
  After = 2
}

const useStyles = makeStyles((theme: Theme) =>
({
  mainContainer: {
    //display: "flex",
    //justifyContent: "center",
    "& .warningText": {
      color: "#c80e49"
    }
  },
  examNameStyle: {
    color: "#003f71",
    fontSize: common.FONT_SIZE.title,
    fontWeight: "bold",
    textAlign: "center"
  },
  subjectStyle: {
    marginRight: "25px",
    "& .discription": {
      fontSize: "1.4rem",
      marginBottom: "0"
    },
    "& .discription-red": {
      fontSize: "1.4rem",
      color: "#c80e49",
      marginBottom: "0"
    }
  },
  buttonContainer: {
    margin: "20px auto",
    textAlign: "center",
    "& h2": {
      fontSize: common.FONT_SIZE.title,
      fontWeight: "bold",
      color: "#00468b"
    },
    "& button": {
      fontSize: common.FONT_SIZE.button,
      padding: "0 2.8rem",
      minWidth: "120px",
      marginTop: "25px",
      height: "40px",
    },
    "& button:hover": {
      backgroundColor: "#003f71",
    },
    "& button:disabled": {
      backgroundImage: "none",
      backgroundColor: "#cccccc",
    }
  },
  mobileButtonContainer: {
    paddingTop: common.FONT_SIZE.responsive_24px,
    textAlign: "center",
    //backgroundColor: "#003f71",
    "& button": {
      fontSize: common.FONT_SIZE.responsive_16px,
      minWidth: "50vw",
      borderRadius: "5.3vw"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
    },
    "& button:disabled": {
      backgroundImage: "none",
      backgroundColor: "#cccccc",
    }
  },
  pcTitleStyle: {
    margin: "20px auto",
    textAlign: "center",
    "& h2": {
      fontSize: common.FONT_SIZE.title,
      fontWeight: "bold",
      marginBottom: "4rem",
      color: "#00468b"
    },
    "& p": {
      fontSize: common.FONT_SIZE.mainText,
      margin: 0
    },
    "& #qrCode": {
      marginTop: "2rem",
      marginBottom: "2rem"
    },
    "& #Title": {
      marginTop: "4rem",
      marginBottom: "2rem"
    }
  },
  mobileTitleStyle: {
    textAlign: "center",
    backgroundColor: "white",
    padding: "8.5vw 2.1vw",
    "& h2": {
      fontSize: common.FONT_SIZE.responsive_24px,
      fontWeight: "bold",
      color: "#00468b",
      marginBottom: common.FONT_SIZE.responsive_18px
    },
    "& p": {
      fontSize: common.FONT_SIZE.responsive_14px,
      margin: 0
    },
    "& li": {
      fontSize: common.FONT_SIZE.responsive_14px,
      margin: 0,
      textAlign: "left"
    },
    "& ul": {
      paddingLeft: "20px"
    }
  },
  mobileTblStyle: {
    margin: "0 auto",
    marginTop: common.FONT_SIZE.responsive_24px,
    fontSize: common.FONT_SIZE.responsive_16px,
    backgroundColor: "#003f71",
    tableLayout: "fixed",
    width: "auto",
    //width: "100%",
    "& td,th,a": {
      position: "relative",
      color: "#accb39",
      fontWeight: "bold",
      textAlign: "left",
      //textAlign: "center"
    },
    "& td": {
      fontSize: common.FONT_SIZE.responsive_18px,
      padding: "2.1vw 0 4.27vw 6vw"
      //padding:"2.1vw 0 4.27vw"
    },
    "& th": {
      color: "#b7cde6",
      textAlign: "left",
      //textAlign: "center",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      paddingTop: "2.1vw",
    },
    "& span": {
      display: "inline-block",
      verticalAlign: "middle"
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: common.FONT_SIZE.responsive_16px,
    },
    "& tbody": {
      padding: "6.4vw",
    }
  },
  tblStyle: {
    tableLayout: "fixed",
    width: "80%",
    borderTop: "solid 1px #bebdc3",
    margin: "40px auto",
    fontSize: common.FONT_SIZE.mainText,
    "& td,th": {
      position: "relative",
      padding: "16px 20px",
      borderBottom: "solid 1px #bebdc3",
      color: "#00468b",
      fontWeight: "bold",
    },
    "& td": {
      width: "75%"
    },
    "& th": {
      color: "#4b4960",
      textAlign: "left",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      width: "25%"
    },
    "& span": {
      display: "inline-block",
      verticalAlign: "middle"
    },
    "& #qrCode": {
      paddingLeft: "15rem"
    }
  },
  mobileHeaderCon: {
    padding: "7.47vw 2.1vw",
    background: "radial-gradient(ellipse at center bottom, rgba(0,137,190,1) 0%,rgba(5,49,113,1) 100%)",
  },
  mobileHeaderTbl: {
    fontSize: common.FONT_SIZE.responsive_16px,
    margin: "0 auto",
    width: "auto",
    "& td,th": {
      position: "relative",
      padding: "2.1vw 2.6vw",
      color: "white",
      fontWeight: "bold",
    },
    "& td": {
      fontSize: common.FONT_SIZE.responsive_18px,
    },
    "& th": {
      color: "#b7cde6",
      textAlign: "left",
      fontWeight: "normal",
      whiteSpace: "nowrap",
    },
    "& span": {
      display: "inline-block",
      verticalAlign: "middle"
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: common.FONT_SIZE.responsive_16px,
    }
  },
  pcWarningTextStyle: {
    fontSize: common.FONT_SIZE.mainText,
    marginBottom: "0",
    marginTop: "5px",
    color: "#c80e49",
    textAlign: "center"
  },
  pcWarningTextStyle_list: {
    textAlign: "left",
    width: "70%",
    margin: "0 auto",
    "& li": {
      padding: "0.3rem 0",
    }
  },
  mobileWarningTextStyle: {
    padding: "0 2.6vw",
    fontSize: common.FONT_SIZE.responsive_16px,
    color: "#c80e49;",
    marginTop: common.FONT_SIZE.responsive_16px,
    textAlign: "center",
    "& .offHours": {
      margin: 0
    }
  },
  loadingStyle: {
    margin: "auto"
  },
  modalText: {
    "& .warning": {
      fontWeight: "bold",
      color: "red",
      fontSize: common.FONT_SIZE.responsive_14px,
      margin: "1rem auto 3rem",
      textAlign: "center"
    }
  }
}));


//各state変数の初期値
const initialLearnerData: learner = {
  learnerNumber: "初期値",
  executionName: "初期値",
  executeStart: "初期値",
  executeEnd: "初期値",
  executeTime: 0,
  executionStartDatetime: "初期値",
  executionEndDatetime: "初期値",
  startStatus: 0,
  termType: 0,
  questionNum: 0,
  leaveFlag: false,
  loginCode: "",
  hasPanoramaVideo: false,
  hasPortrait: false,
  deviceCheckIdMobile: "",
  deviceCheckIdPC: "",
  executionPCIP: "",
  executionSPIP: ""
}

export const initialExamQuestions: ExamQuestions = {
  id: 999999,
  page: 999999,
  sectionFlag: false,
  parentQuestionId: 999999,
  answerStatus: 0,
  subjectText: "初期値"
}

export const initialExam: Exam = {
  name: "初期値",
  status: 999999,
  termType: 999999,
  shuffleFlag: false,
  numberingType: 999999,
  acceptStart: "初期値",
  acceptEnd: "初期値",
  executeStart: "0001-01-01T00:00:00.0000000",
  executeEnd: "0001-01-01T00:00:00.0000000",
  executeTime: 999999,
  learnerMax: 999999,
  guidanceUrl: "初期値",
  startViewFlag: false,
  startBodyHtml: "初期値",
  endViewFlag: false,
  endBodyHtml: "初期値",
  allPoints: 999999,
  passPoints: 999999,
  choiceDeviceFlag: false,
  afterReadFlag: false,
  questionNum: 999999,
  pageMax: 999999,
  startStatus: 0,
  startSeconds: 0,
  endSeconds: 0,
  questions: [initialExamQuestions],
  extensions: [],
  advanceExplanationVideoUrl: "",
  executionPlaces: []
}

export function Home() {

  //-----スタイルの宣言-------
  const classNames = useStyles();

  //----stateの定義---------
  const [examStateValue, setExamStateValue] = React.useState<Exam>(initialExam);
  const [learnerStateValue, setLearnerStateValue] = React.useState<learner>(initialLearnerData);
  const [mobileUrl, setMobileUrl] = React.useState("");

  const [isfinishedExecution, setIsfinishedExecution] = React.useState<boolean>(false);
  const [isTested, setIsTested] = React.useState<boolean>(false);
  const [isMobileFlag, setIsMobileFlag] = React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isShowStartScreen, setIsShowStartScreen] = React.useState(false);
  const [deviceTypeLabel, setDeviceTypeLabel] = React.useState<string>("PC");

  const [completeProcess, setCompleteProcess] = React.useState<string>("");

  const [isNotYetTested, setIsNotYetTested] = React.useState<boolean>(false);

  //モーダル関係
  const [openModal, setOpenModal] = React.useState(false);
  const [modalFunction, setModalFunction] = React.useState<any>();
  const [modalBody, setModalBody] = React.useState<JSX.Element>(<div />);

  //----共通関数の定義-------
  const {
    params,
    go, // 画面遷移 
    api,  // API呼び出し
    appContext,
    setAppContext
  } = common.useCommon();

  //------API-------
  function getExam(args?: any) {
    return api("/api/l-exam", "GET", args)
  }

  function putExam(args?: any) {
    return api("/api/l-exam", "PUT", args)
  }

  function getLearner(args?: any) {
    return api("/api/l-learner", "GET", args)
  }

  //-----汎用関数の定義-------

  const goExamPage = (examStartStatus: ExamStartStatus) => {
    switch (examStartStatus) {
      case 0: isMobileFlag ? go("/mobile/standby-screen") : go("/pc/standby-screen"); break;
      case 1: isMobileFlag ? go("/mobile/home") : go("/pc/home"); break;
      case 2: isMobileFlag ? go("/mobile/home") : go("/pc/home"); break;
    }
  };

  //任意開始タイプの試験の時に、試験開始時間前かどうかを判定
  const isBeforeStart = () => {
    const d1 = new Date();
    const d2 = new Date(examStateValue.executeStart);
    const timeDifference = d2.getTime() - d1.getTime();
    if (timeDifference > 0) return true
    return false
  }


  //試験開始状態を更新する
  const putStartProcess = () => {
    putExam({ start: true })
      .then((res: any) => {
        if (res.errorCode !== 20000) {
          common.alertError(res.errorTitle, res.errorDetail);
          return;
        }

        goExamPage(ExamStartStatus.InProcess);

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_PutExam);
      });
  }


  const branchByMobileStartStatus = () => {
    getExam()
      .then((res: APIResponse<Exam>) => {
        if (res.errorCode !== 20000) {
          common.alertError(res.errorTitle, res.errorDetail);
          return;
        }

        if (res.value !== null) {
          goExamPage(res.value.startStatus);
          return;
        }

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_GetExam);
      });
  }


  //モバイル分離の際の試験開始処理
  const putStartProcess_DeviceChoice = () => {
    getLearner()
      .then((res: APIResponse<learner>) => {
        if (res.errorCode !== 20000) {
          common.alertError(res.errorTitle, res.errorDetail);
          return;
        }

        if (res.value !== null) {

          //受験開始していた場合
          if (res.value.executionStartDatetime !== null) {
            branchByMobileStartStatus();
            return;
          }

          if (examStateValue.startViewFlag) {
            //試験開始画面を表示する
            setIsShowStartScreen(true);
          }
          else {
            putStartProcess();
          }

        }

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_GetLearner);
      });

  }

  //PCのみで受験の時の試験開始処理
  const putStartProcessByPC = () => {

    if (examStateValue.startViewFlag) {
      //試験開始画面を表示する
      setIsShowStartScreen(true);
    }
    else {
      putStartProcess();
    }

  }

  const branchTransition = () => {

    if (learnerStateValue.executionStartDatetime === null && !isfinishedExecution && !isBeforeStart()) {
      examStateValue.choiceDeviceFlag ? putStartProcess_DeviceChoice() : putStartProcessByPC();
      return;
    }

    goExamPage(examStateValue.startStatus);

  }



  const branchTransitionByMobile = () => {


    if (!examStateValue.choiceDeviceFlag) {
      openWarningModal(() => setOpenModal(false), "この試験はモバイル端末では受験出来ません");
      return;
    }

    getLearner()
      .then((learnerRes: APIResponse<learner>) => {
        if (learnerRes.errorCode !== 20000) {
          common.alertError(learnerRes.errorTitle, learnerRes.errorDetail);
          return;
        }


        if (learnerRes.value.executionStartDatetime === null && !isfinishedExecution && !isBeforeStart()) {
          openWarningModal(() => setOpenModal(false), "PCサイトで試験を開始した後に再接続してください。");
          return;
        } else if (learnerRes.value.executionStartDatetime !== null && !isfinishedExecution && !isBeforeStart()) {
          goExamPage(ExamStartStatus.InProcess);
          return;
        }

        branchByMobileStartStatus();

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_GetLearner);
      });
  }


  //試験開始前引数の分数以内かどうかの判定
  const isStartWithinMinutes = (time: number) => {

    const d1 = new Date();
    const d2 = new Date(examStateValue.executeStart);
    const timeDifference = d2.getTime() - d1.getTime();
    if (timeDifference > time * 60 * 1000) return false
    return true

    }
    
  //試験開始前引数の分数以内かどうかの判定(useEffect内で使用するため試験を引数に取る)
  const isStartWithinMinutes_NeedExam = (exam: Exam, time: number) => {

    const d1 = new Date();
    const d2 = new Date(exam.executeStart);
    const timeDifference = d2.getTime() - d1.getTime();
    if (timeDifference > time * 60 * 1000) return false
    return true

  }
    
  //試験終了後引数の分数経過したかどうかの判定
  const isFinishOverMinutes = (time: number) => {

    const d1 = new Date(); // 現在時刻
    const d2 = new Date(examStateValue.executeEnd); // 試験終了日時
    const timeDifference = d1.getTime() - d2.getTime();
    if (timeDifference >= time * 60 * 1000) return true
    return false

  }

  //試験開始画面からHome画面へ戻る処理
  const returnHome = () => {
    setIsShowStartScreen(false);
  }


  const openWarningModal = (executionFunc: any, text: string) => {
    setModalFunction(() => executionFunc);
    setModalBody(
      <div className={classNames.modalText}>
        <p className="warning">{text}</p>
      </div>
    );
    setOpenModal(true);
  }

  const blockBrowserBack = React.useCallback(() => {
    window.history.go(1)
　}, [])

  // -----use effefct-----
  React.useEffect(() => {

    let examValue = initialExam;

    getExam()
      .then((resExam: APIResponse<Exam>) => {
        if (resExam.errorCode !== 20000) {
          common.alertError(resExam.errorTitle, resExam.errorDetail);
          return;
        }

        if (resExam.value !== null) {

          examValue = resExam.value;
          setExamStateValue(examValue);

          if (resExam.value.startStatus === 2) {

            setIsfinishedExecution(true);

          }

          // 有効な機能
          const exts_on = resExam.value.extensions
            .filter(x => x.parameter == 1)
            .map(x => x.code);

          getLearner()
            .then((res: APIResponse<learner>) => {
              if (res.errorCode !== 20000) {
                common.alertError(res.errorTitle, res.errorDetail);
                return;
              }

              if (res.value !== null) {

                //モバイル分離テストかつ、モバイル端末で画面を表示している場合の処理
                if (examValue.choiceDeviceFlag && isMobile) {

                  if (exts_on.includes(ExtCode.self_photo) && !res.value.hasPortrait) {
                    // 本人写真機能：有効
                    // 撮影ページへ遷移
                    go("/mobile/take-photo");
                  } else if (exts_on.includes(ExtCode.env_record) && !res.value.hasPanoramaVideo) {
                    // 周囲撮影機能：有効
                    // 撮影ページへ遷移
                    go("/mobile/env-movie");
                  } else {
                    // 撮影系全て終了をセット
                    setCompleteProcess("camera");
                  }

                }



                //試験中or試験開始前15分以内の時、本人写真、録画済みの場合待機画面へ遷移する（PC画面）
                //※「res.value.hasPanoramaVideo (録画済みフラグ)も判定に入れるべきだが、録画が上手くいかないことが多いため、ひとまず写真の判定のみにした」
                if ((res.value.startStatus === 1 || isStartWithinMinutes_NeedExam(examValue, 15))
                  && !isMobile
                  && res.value.executionEndDatetime == null) {
                    if (exts_on.includes(ExtCode.self_photo) && !res.value.hasPortrait && examValue.choiceDeviceFlag) {
                    // 本人写真機能：有効で未撮影
                    } else if (exts_on.includes(ExtCode.env_record) && !res.value.hasPanoramaVideo && examValue.choiceDeviceFlag) {
                    // 周囲撮影機能：有効で未撮影
                  } else {
                    // 撮影系全て終了済みで試験未終了なら、待機画面へ
                    go("/pc/standby-screen");
                  }
                }

                setLearnerStateValue(res.value);
                //受験済みかどうか
                if (res.value.executionEndDatetime !== null) {
                  setIsfinishedExecution(true);
                  setIsTested(true);
                }
                if (appContext.fakeapi_mode && appContext.fake_synctoken) {
                  // 模擬試験モードでは、飛び先はチュートリアルトップで同期トークン付き
                  //setMobileUrl(window.location.origin + "/tutorial?token=" + appContext.fake_synctoken);
                  // 模擬試験モードでは、飛び先はサンプル試験で同期トークン付き
                  setMobileUrl(window.location.origin + "/tutorial2/guide/exam?token=" + appContext.fake_synctoken);
                } else {
                  //モバイル端末でログインするためのQRコードのURLに受験者IDをパラメータとして付加
                  const path = window.location.origin + "/Identity/Account/Login?code=" + res.value.loginCode;
                  setMobileUrl(path);
                  }

                  // 試験終了後に未受験かどうか
                  if (resExam.value.startStatus === 2 && res.value.executionStartDatetime === null) {
                      setIsNotYetTested(true);
                  }
              }


            })
            .catch((err: any) => {
              alert(common.ResponseMessages.Error_GetLearner);
            });

          setIsLoaded(true);

        }

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_PostExam);
      });




    setIsMobileFlag(isMobile);

  }, []);

  // ブラウザバッグ無効化
  React.useEffect(() => {
    // 直前の履歴に現在のページを追加
    window.history.pushState(null, '', window.location.href)

    // 直前の履歴と現在のページのループ
    window.addEventListener('popstate', blockBrowserBack)

    // クリーンアップは忘れない
    return () => {
        window.removeEventListener('popstate', blockBrowserBack)
    }
}, [blockBrowserBack])

  //completeProcessの値が変わると同期送信する
  // React.useEffect(() => {
  //   sendSyncCurrentState();
  // }, [completeProcess]);




  /**
  * 同期処理初期化
  */
  useSync("Home", isMobile, async (label, data) => {

    if (typeof (data.completeProcess) !== "string") {
      return;
    }

    //モバイル端末の場合
    if (isMobile) {

      switch (data.completeProcess) {
        case "start":
          go("/mobile/home");
          break;

        default:
          break;
      }

      //PC端末の場合
    } else {

      switch (data.completeProcess) {
        case "camera":
          go("/pc/standby-screen");
          break;

        default:
          break;
      }

    }

  });

  /**
   * 同期処理送信処理
   */
  function sendSyncCurrentState() {
    // stateの変更は非同期なので、
    // 更新後の値で同期する
    setCompleteProcess(_completeProcess => {
      sendSync("Home", {
        completeProcess: _completeProcess
      });
      return _completeProcess;
    });
  }


  //QRコード表示の有効、無効化判定のフラグ                                                                    ここの数字(分)で試験開始何分前からQRコードが表示されるか指定可能
  const disabledQRCodeFlag = (isfinishedExecution && !examStateValue.afterReadFlag) || !isStartWithinMinutes(15) || examStateValue.status === 0

  const diabledMobileStartButtonFlag = !isStartWithinMinutes(0) || examStateValue.status === 0





  return (
    <Layout viewType={isMobileFlag ? "mobile" : "pc"} width={isMobileFlag ? "90%" : "900px"} isTutorial={appContext.fakeapi_mode}>

      {isLoaded ?

        <div className={classNames.mainContainer}>

          {isShowStartScreen ?

            <StartScreen isMobile={isMobileFlag} exam={examStateValue} handleStartClick={putStartProcess} returnHome={returnHome} mobileUrl={mobileUrl} />

            :

            <div>


              {/* <label style={{ marginRight: "15px" }}>{deviceTypeLabel}</label>
              <Button onClick={() => {
                const flag = !isMobileFlag;
                setIsMobileFlag(flag);
                setDeviceTypeLabel(deviceTypeLabel === "PC" ? "mobile" : "PC");
              }} color="default" variant="contained">
                端末切り替え
              </Button> */}



              <CustomModal
                isOpen={openModal}
                setIsOpenFunc={setOpenModal}
                onOKFunc={modalFunction}
                isNotice={true}
              >
                {modalBody}
              </CustomModal>


              <div className={isMobileFlag ? classNames.mobileTitleStyle : classNames.pcTitleStyle}>

                <div hidden={isfinishedExecution || disabledQRCodeFlag || isMobileFlag}>

                  {
                    examStateValue.choiceDeviceFlag
                      ?

                      <div>
                        <p>この試験では問題文をPCで表示し、解答をモバイル端末で行います。</p>
                        <p>モバイル端末で下記QRコードから解答ページへ接続し、本人写真撮影、周囲録画を完了して下さい。</p>
                        {mobileUrl ? <p id="qrCode"><QrCode text={mobileUrl} /></p> : undefined}
                        <p className="text-danger">本人写真撮影、周囲録画を完了するとこの画面は試験開始待機画面へ自動で切り替わります。<br />試験を開始する前に、スマートフォンの前面カメラで本人写真の撮影が必要です。</p>

                      </div>

                      :

                      <div>
                        <p>試験を開始する場合は「試験を開始する」ボタンを押してください。</p>
                        {/* <p className="text-danger">試験中に受験者の映像を録画、録音するため、使用するブラウザの<br />マイク／カメラのアクセスが許可されていることを確認して下さい。</p>*/}
                      </div>

                  }

                </div>

                <div hidden={isfinishedExecution || disabledQRCodeFlag || !examStateValue.choiceDeviceFlag || !isMobileFlag}>

                  <p style={{ fontSize: "5.3vw", fontWeight: "bold" }} className="text-danger">この画面を開いたままにして下さい。</p>
                  <p className="text-danger">PC画面から試験を開始すると解答画面へ自動的に遷移します。</p>

                  {/*<p className="text-danger"><br />※自動的に遷移しない、試験開始後のアクセスの場合は、「解答画面を開く」ボタンをクリックして下さい。<br /></p>*/}
                  <p className="text-danger"><br />※自動的に遷移しなかった場合は、再読み込みをお願いします。<br /></p>
                  <p className="text-danger">※試験時間が過ぎた後にアクセスされた場合は「解答画面を開く」ボタンをタップしてください。<br /></p>


                  <ul>
                    <li>この試験では問題文をPCで表示し、解答をモバイル端末で行います。</li>
                    {/* 意見及び指摘事項2-79により下記文言を削除
                    {/*<li>試験中に受験者の映像を録画、録音するため、使用するブラウザのマイク／カメラのアクセスが許可されていることを確認して下さい。</li>*/}
                  </ul>

                  <div>

                    <div style={{ fontWeight: "bold" }} className={classNames.mobileWarningTextStyle} hidden={!isTested}>この試験は解答済みです</div>
                    
                    <div className={classNames.mobileButtonContainer}>
                      <DefaultButton disabled={diabledMobileStartButtonFlag} hidden={isfinishedExecution || isTested}
                        onClick={() => {
                          branchTransitionByMobile()
                        }}
                      >解答画面を開く</DefaultButton>
                    </div>

                    {/* <DefaultButton 
                        onClick={() => {
                          sendSyncCurrentState()
                        }}
                      >同期</DefaultButton> */}

                    <div style={{ fontWeight: "bold" }} className={classNames.mobileWarningTextStyle} hidden={!isfinishedExecution || isTested}>この試験は終了しました</div>
                    <div className={classNames.mobileWarningTextStyle} hidden={isStartWithinMinutes(0)}>
                      <p className="offHours">試験時間になってもボタンが押せない場合は</p>
                      <p className="offHours">ブラウザを更新して下さい。</p>
                    </div>

                  </div>


                </div>

              </div>

              {
                isMobileFlag ?

                  <div> </div>

                  :

                  <div>
                    <div style={{ fontWeight: "bold" }} className={classNames.pcWarningTextStyle} hidden={!isTested}>この試験は解答済みです</div>
                    
                    <div style={{ fontWeight: "bold" }} className={classNames.pcWarningTextStyle} hidden={!isNotYetTested}>この試験は受験されませんでした</div>

                    <div className={classNames.pcWarningTextStyle} hidden={!isfinishedExecution || examStateValue.afterReadFlag}>この試験では試験終了後の問題の表示は出来ません</div>
          
                    <div className={classNames.pcWarningTextStyle} hidden={isStartWithinMinutes(15)}>
                      現在、試験時間外ですので試験開始までしばらくお待ちください。<br /><br />
                      <div hidden={!examStateValue.choiceDeviceFlag && !examStateValue.advanceExplanationVideoUrl && !examStateValue.executionPlaces}>
                        試験開始に先立ちまして以下の準備が必要になります。なお、以下の準備は試験開始の15分前から<br />行うことができます。<br /><br />
                        <ul className={classNames.pcWarningTextStyle_list}>
                          <li hidden={!examStateValue.choiceDeviceFlag}>本人写真の撮影<br />スマートフォンの前面カメラでご自身の撮影をしていただきます</li>
                          <li hidden={!examStateValue.advanceExplanationVideoUrl}>試験前注意事項動画の視聴<br />試験に関する注意事項を説明する動画を視聴していただきます</li>
                          <li hidden={!examStateValue.executionPlaces}>会場コードの入力<br />当日に試験会場内で発表される会場コードを入力していただきます</li>
                        </ul><br />
                      </div>
                      試験の開始時間の15分前になりましたら、再度こちらのページにアクセスするか、ブラウザの<br />再読み込みをお願いします。
                    </div>

                    <div className={classNames.pcWarningTextStyle} hidden={!isStartWithinMinutes(15) || examStateValue.status !== 0}>ただいま準備中です。<br />適宜画面を再読み込みしてください。</div>
                    
                    <div className={classNames.pcWarningTextStyle} hidden={!isfinishedExecution || !examStateValue.afterReadFlag || isNotYetTested}>試験終了時間から10分経過すると試験問題の確認が可能になります。<br />試験終了時間から10分経過しても【試験問題を確認する】ボタンが押下できない場合、<br />画面を再読み込みしてください。</div>

                    <div className={classNames.buttonContainer} hidden={!isfinishedExecution || isNotYetTested}>
                        <DefaultButton disabled={disabledQRCodeFlag || !isFinishOverMinutes(10)} style={disabledQRCodeFlag ? { border: "none" } : {}}
                            onClick={() => {

                                branchTransition();

                            }}
                        >試験問題を確認する</DefaultButton>
                      </div>



                  </div>

              }

              <div style={{ backgroundColor: isMobileFlag ? "#003f71" : "white", paddingBottom: "20px", height: isMobileFlag ? "100%" : "auto" }}>

                {isMobileFlag

                  ?

                  <div >
                    <div className={classNames.mobileHeaderCon}>
                      <table className={classNames.mobileHeaderTbl}>
                        <tbody>
                          <tr >
                            <th><PersonSharpIcon fontSize="small" /><span>&nbsp;受験番号</span></th>
                            <td>{learnerStateValue.learnerNumber}</td>
                          </tr>
                          <tr >
                            <th><DescriptionIcon fontSize="small" /><span>&nbsp;試験名称</span></th>
                            <td>{examStateValue.name}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* <div>

                      <div style={{ fontWeight: "bold" }} className={classNames.mobileWarningTextStyle} hidden={!isTested}>この試験は解答済みです</div>

                      <div className={classNames.mobileButtonContainer}>
                        <DefaultButton disabled={diabledMobileStartButtonFlag} hidden={isfinishedExecution || isTested}
                          onClick={() => {

                            branchTransitionByMobile();

                          }}
                        >解答画面を開く</DefaultButton>
                      </div>

                      <div style={{ fontWeight: "bold" }} className={classNames.mobileWarningTextStyle} hidden={!isfinishedExecution || isTested}>この試験は終了しました</div>

                      <div className={classNames.mobileWarningTextStyle} hidden={isStartWithinMinutes(0)}>
                        <p className="offHours">試験時間になってもボタンが押せない場合は</p>
                        <p className="offHours">ブラウザを更新して下さい。</p>
                      </div>

                    </div> */}

                    <table className={classNames.mobileTblStyle}>
                      <tbody>
                        <tr >
                          <th><QueryBuilderIcon fontSize="small" /><span>&nbsp;試験開始時間</span></th>
                        </tr>
                        <tr >
                          <td>{common.dateFormat(examStateValue.executeStart, "llll")}</td>
                        </tr>
                        <tr >
                          <th><WatchLaterIcon fontSize="small" /><span>&nbsp;試験終了時間</span></th>
                        </tr>
                        <tr >
                          <td>{common.dateFormat(examStateValue.executeEnd, "llll")}</td>
                        </tr>
                        <tr >
                          <th><AlarmOnIcon fontSize="small" /><span>&nbsp;試験時間</span></th>
                        </tr>
                        <tr >
                          <td>{examStateValue.executeTime} 分</td>
                        </tr>
                        <tr >
                          <th><NoteIcon fontSize="small" /><span>&nbsp;問題数</span></th>
                        </tr>
                        <tr >
                          <td>{examStateValue.questionNum} 問</td>
                        </tr>
                        <tr hidden={examStateValue.guidanceUrl === ""}>
                          <th><LinkIcon fontSize="small" /><span>&nbsp;試験案内URL</span></th>
                        </tr>
                        <tr hidden={examStateValue.guidanceUrl === ""}>
                          <td><a href={examStateValue.guidanceUrl}>{examStateValue.guidanceUrl}</a></td>
                        </tr>

                      </tbody>
                    </table>
                    <div>
                        <div style={{ fontWeight: "bold" }} className={classNames.mobileWarningTextStyle} hidden={!isTested}>この試験は解答済みです</div>
                        
                        <div style={{ fontWeight: "bold" }} className={classNames.mobileWarningTextStyle} hidden={!isNotYetTested}>この試験は受験されませんでした</div>
                    </div>


                  </div>

                  :

                  <table className={classNames.tblStyle}>
                    <tbody>
                      <tr>
                        <th className={classNames.subjectStyle} ><PersonSharpIcon fontSize="large" /><span>&nbsp;&nbsp;受験番号</span></th>
                        <td>{learnerStateValue.learnerNumber}</td>
                      </tr>
                      <tr >
                        <th className={classNames.subjectStyle}><DescriptionIcon fontSize="large" /><span>&nbsp;&nbsp;試験名称</span></th>
                        <td>{examStateValue.name}</td>
                      </tr>
                      <tr >
                        <th className={classNames.subjectStyle}><QueryBuilderIcon fontSize="large" /><span>&nbsp;&nbsp;試験開始時間</span></th>
                        <td>{common.dateFormat(examStateValue.executeStart, "llll")}</td>
                      </tr>
                      <tr >
                        <th className={classNames.subjectStyle}><WatchLaterIcon fontSize="large" /><span>&nbsp;&nbsp;試験終了時間</span></th>
                        <td>{common.dateFormat(examStateValue.executeEnd, "llll")}</td>
                      </tr>
                      <tr >
                        <th className={classNames.subjectStyle}><AlarmOnIcon fontSize="large" /><span>&nbsp;&nbsp;試験時間</span></th>
                        <td>{examStateValue.executeTime} 分</td>
                      </tr>
                      <tr >
                        <th className={classNames.subjectStyle}><NoteIcon fontSize="large" /><span>&nbsp;&nbsp;問題数</span></th>
                        <td>{examStateValue.questionNum} 問</td>
                      </tr>
                      <tr hidden={!examStateValue.guidanceUrl}>
                        <th className={classNames.subjectStyle}><LinkIcon fontSize="large" /><span>&nbsp;&nbsp;試験案内URL</span></th>
                        <td><a href={examStateValue.guidanceUrl}>{examStateValue.guidanceUrl}</a></td>
                      </tr>
                      <tr hidden={!examStateValue.advanceExplanationVideoUrl}>
                        <th>試験説明動画</th>
                        <td>
                          <ReactPlayer
                            url={examStateValue.advanceExplanationVideoUrl}
                            playsinline
                            controls={true}
                            height="254px"
                            width="452px"
                            crossOrigin="anonymous"
                          />
                        </td>
                      </tr>
                      {/* {examStateValue.choiceDeviceFlag &&

                        <tr >
                          <th className={classNames.subjectStyle}><PhoneIphoneIcon fontSize="large" />
                            <span>&nbsp;&nbsp;モバイル解答ページQRコード</span>
                            <p className="discription" style={{ marginTop: "2rem" }}>モバイル端末で右のQRコードを読み取</p>
                            <p className="discription">り、モバイル解答ページを開いてください。</p>
                            <p className="discription-red">PCサイトで試験開始後、モバイルサイトで</p>
                            <p className="discription-red">解答を開始できます。</p>
                          </th>
                          {mobileUrl ? <td id="qrCode"><QrCode text={mobileUrl} /></td> : undefined}
                        </tr>

                      } */}
                    </tbody>
                  </table>


                }




              </div>

            </div>

          }
        </div>


        : <ReactLoading type={"spin"} color={"#003f71"} height={isMobileFlag ? '3rem' : '7rem'} width={isMobileFlag ? '3rem' : '7rem'} className={classNames.loadingStyle} />

      }
    </Layout>
  );
}