import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import * as common from "../../common";
import { Layout } from "../Layout";
import { DefaultButton } from '../StylesUI/CommonLayouts';
import { RecordingScreen } from '../mobile/Exam/RecordingScreen';
import useMobileDeviceCheck from '../mobile/MobileDeviceCheckHook';

const useStyles2 = makeStyles((theme: Theme) =>
({
  mainContainer: {
    //display: "flex",
    //justifyContent: "center",
    "& .warningText": {
      color: "#c80e49"
    }
  },
  mobileButtonContainer: {
    paddingTop: common.FONT_SIZE.responsive_24px,
    textAlign: "center",
    //backgroundColor: "#003f71",
    "& button": {
      fontSize: common.FONT_SIZE.responsive_16px,
      minWidth: "50vw",
      borderRadius: "5.3vw"
    },
    "& button:hover": {
      //  backgroundColor: "#003f71",
    },
    "& button:disabled": {
      backgroundImage: "none",
      backgroundColor: "#cccccc",
    }
  },
  mobileTitleStyle: {
    textAlign: "center",
    backgroundColor: "white",
    padding: "8.5vw 2.1vw",
    "& h2": {
      fontSize: common.FONT_SIZE.responsive_24px,
      fontWeight: "bold",
      color: "#00468b",
      marginBottom: common.FONT_SIZE.responsive_18px
    },
    "& p": {
      fontSize: common.FONT_SIZE.responsive_14px,
      margin: 0
    },
    "& li": {
      fontSize: common.FONT_SIZE.responsive_14px,
      margin: 0,
      textAlign: "left"
    },
    "& ul": {
      paddingLeft: "20px"
    }
  },
  mobileTblStyle: {
    margin: "0 auto",
    marginTop: common.FONT_SIZE.responsive_24px,
    fontSize: common.FONT_SIZE.responsive_16px,
    backgroundColor: "#003f71",
    tableLayout: "fixed",
    width: "auto",
    //width: "100%",
    "& caption": {
      captionSide: "top",
      textAlign: "center",
      fontSize: common.FONT_SIZE.responsive_18px,
      fontWeight: "bold",
    },
    "& td,th,a": {
      position: "relative",
      color: "#accb39",
      fontWeight: "bold",
      textAlign: "left",
      //textAlign: "center"
    },
    "& td": {
      fontSize: common.FONT_SIZE.responsive_18px,
      padding: "2.1vw 2.1vw 4.27vw 6vw"
      //padding:"2.1vw 0 4.27vw"
    },
    "& th": {
      color: "#b7cde6",
      textAlign: "left",
      //textAlign: "center",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      paddingTop: "2.1vw",
      paddingLeft: "2.1vw",
    },
    "& span": {
      display: "inline-block",
      verticalAlign: "middle"
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: common.FONT_SIZE.responsive_16px,
    },
    "& tbody": {
      padding: "6.4vw",
    }
  },
  mainVideo: {
    width: "100%"
  },
  loadingStyle: {
    margin: "auto"
  },
  modalText: {
    "& .warning": {
      fontWeight: "bold",
      color: "red",
      fontSize: common.FONT_SIZE.responsive_14px,
      margin: "1rem auto 3rem",
      textAlign: "center"
    }
  },

  // ここから

  mobImgBtn: {
    //marginBottom: "4vw !important",
    "& button": {
      minWidth: "initial !important",
      height: "auto !important",
      padding: "0 !important",
      margin: "0 !important",
      border: "none !important",
      backgroundColor: "transparent !important",
      color: "#4378b6",
      fontWeight: "bold",
    },
    "& button > span": {
      display: "inline-block",
      padding: "2vw",
    },
    "& button > span > img": {
      width: "20vw",
      height: "20vw",
    }
  },
  mobNav: {
    "& button": {
      fontSize: "3.4vw"
    },
    "& button > span": {
      //position: "relative",
      top: "2.4vw",
      padding: "2vw 2vw 2vw 0",
    },
    "& button > span > img": {
      width: "8vw",
      height: "8vw",
    }
  },
  mobBottomNav: {
    marginTop: "4vw",
    padding: "0 4vw",
    borderTop: "solid 1px #cccccc"
  },
  mobMainCon: {
    "& h1": {
      padding: "8px",
      margin: "0 0 20px",
      borderTop: "solid 4px #76a6d4",
      borderBottom: "solid 1px #d0d0d0",
      background: "linear-gradient(to bottom, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%)",
      color: "#333",
      fontSize: "4.8vw",
      fontWeight: "bold",
      textAlign: "left"
    },
    "& $mobileTblStyle": {
      width: "100%",
      backgroundColor: "transparent",
    },
    "& $mobileTblStyle td": {
      padding: "4vw 4vw 6vw 6vw",
      color: "#00468b"
    },
    "& $mobileTblStyle th": {
      padding: "1.4vw 2vw",
      borderLeft: "solid 4vw #76a6d4",
      backgroundColor: "#f0f0f0",
      color: "#333333"
    },
    "&.MuiLinearProgress-root": {
      height: "1.6vw",
      borderRadius: "0.8vw",
    }
  },
  checkIcon: {
    "&:: after": {
      content: "\"\"",
      position: "absolute",
      display: "inline-block",
      margin: "1vw 0 0 1vw",
      width: "4.8vw",
      height: "4.8vw",
    }
  },
  noconf: {
    color: "#c5003f",
    "&::after": {
      background: "transparent url(/static/images/ico_no.png)  no-repeat left top / contain"
    }
  },
  conf: {
    color: "#32b16c",
    "&::after": {
      background: "transparent url(/static/images/ico_ok.png)  no-repeat left top / contain"
    }
  }
}));

export function TutorialCheckMobile() {

  const {
    go
  } = common.useCommon();

  const classNames2 = useStyles2();
  // イベントハンドラと状態変数
  const {
    isSpeedChecked,
    isValidSpeed,
    sp_download_mb,
    sp_upload_mb,
    up_progress_val,
    dl_progress_val,
    micChecking,
    isMicChecked,
    isValidMic,
    micLabel,
    camChecking,
    isValidCam,
    camLabel,
    isCamChecked,
    onReturnDeviceCheck,
    onSaveDeviceCheckResult,
    onStartSpeedCheck,
    onStartMicCheck,
    recordingCallback,
    onStartCamCheck,
  } = useMobileDeviceCheck();

  return (
    <Layout viewType="mobile" width="90%" isTutorial={true}>

      <div className={classNames2.mobMainCon}>
        <div className={classNames2.mainContainer}>
          <div className={classNames2.mobNav + " " + classNames2.mobImgBtn}>
            <button onClick={() => go("/tutorial2")}>
              <span>
                <img
                  alt='チュートリアルトップへ戻る'
                  src="/static/images/ico_return.png" />
              </span>
              チュートリアルトップへ戻る
            </button>
          </div>
        </div>
        <h1>速度検証</h1>
        <div
          className={classNames2.mobileButtonContainer + " " + classNames2.mobImgBtn}>
          <button onClick={onStartSpeedCheck}>
            <span>
              <img
                alt='速度確認開始'
                src="/static/images/ico_dashboard.png" />
            </span>
            <br />速度確認開始
          </button>
        </div>
        <table className={classNames2.mobileTblStyle}>
          <caption
            className={
              isSpeedChecked ? (
                classNames2.checkIcon + " " + (
                  isValidSpeed ? classNames2.conf : classNames2.noconf
                )) : ""
            }>
            {
              isSpeedChecked ? (
                "速度確認結果 : " +
                (isValidSpeed ? "適合" : "不適合")
              ) : ""
            }
          </caption>
          <tr>
            <th>アップロード（動画サーバー）</th>
          </tr>
          <tr>
            <td hidden={isSpeedChecked}>
              <LinearProgress
                color='primary'
                variant="determinate"
                value={up_progress_val} style={{ height: "12px", borderRadius: "6px"}} />
            </td>
            <td hidden={!isSpeedChecked}>
              {sp_upload_mb} Mbps
            </td>
          </tr>
          <tr>
            <th>ダウンロード（動画サーバー）</th>
          </tr>
          <tr>
            <td hidden={isSpeedChecked}>
              <LinearProgress
                color='primary'
                variant="determinate"
                value={dl_progress_val} style={{ height: "12px", borderRadius: "6px" }} /></td>
            <td hidden={!isSpeedChecked}>
              {sp_download_mb} Mbps
            </td>
          </tr>
        </table>
      </div>

      <div
        className={classNames2.mobMainCon}
        hidden={!isSpeedChecked}>
        <div>
          <h1>マイク入力検証</h1>
          <div
            className={classNames2.mobileButtonContainer + " " + classNames2.mobImgBtn}>
            <button onClick={onStartMicCheck}>
              <span>
                <img
                  alt='マイク入力確認'
                  src="/static/images/ico_mic.png" />
              </span>
              <br />  {
                micChecking ? <CircularProgress color='inherit' /> : undefined
              }
              {
                micChecking ? "マイク入力確認終了" : "マイク入力確認開始"
              }
            </button>
          </div>
          <table
            className={classNames2.mobileTblStyle}
            hidden={!isMicChecked}>
            <caption className={classNames2.checkIcon + " "
              + (isValidMic ? classNames2.conf : classNames2.noconf)}>
              マイク確認結果&nbsp;：&nbsp;
              {
                (isValidMic) ? "適合" : "不適合"
              }
            </caption>
            <tr>
              <th>有効な音声入力</th>
            </tr>
            <tr>
              <td>{isValidMic ? micLabel :
                "マイクが検出されませんでした。"
                + "声が小さいと検出されない場合がありますので再度お試し下さい。"
              }</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        className={classNames2.mobMainCon}
        hidden={
          !isSpeedChecked
          || !isMicChecked
        }
      >
        <div>
          <h1>カメラ入力検証</h1>
          <div>
            <p>周囲が３６０°すべて映るように、ゆっくりと一周し終わったら、「録画完了」で撮影を完了してください。</p>
            <div className={classNames2.mainVideo}>
              <RecordingScreen
                anonym={true}
                key={camChecking.toString()}
                hiddenFlag={!camChecking}
                onStartError={(err) => {
                  console.error(err);
                }}
                mode="environment"
                callback={recordingCallback}
              />
            </div>
            <div className={classNames2.mobileButtonContainer + " " + classNames2.mobImgBtn}>
              <button onClick={onStartCamCheck} >
                <span>
                  <img
                    alt='録画開始'
                    src="/static/images/ico_webcam.png" />
                </span>
                <br />    {
                  camChecking ? "録画完了" : "録画開始"
                }
              </button>
            </div>
            <table
              className={classNames2.mobileTblStyle}
              hidden={!isCamChecked}
            >
              <caption className={classNames2.checkIcon + " "
                + (isValidCam ? classNames2.conf : classNames2.noconf)}>
                カメラ確認結果&nbsp;：&nbsp;
                {
                  (isValidCam) ? "適合" : "不適合"
                }
              </caption>
              <tr>
                <th>有効なカメラ入力</th>
              </tr>
              <tr>
                <td>{camLabel ?? ""}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div
        className={classNames2.mobileButtonContainer}
        hidden={
          !isSpeedChecked
          || !isMicChecked
          || !isCamChecked
        }
      >
        <DefaultButton onClick={onSaveDeviceCheckResult}>
          検証結果をサーバーに保存
        </DefaultButton>
      </div>

      <div className={classNames2.mobNav + " " + classNames2.mobBottomNav + " " + classNames2.mobImgBtn}>
        <button onClick={() => go("/tutorial2")}>
          <span>
            <img
              alt='チュートリアルトップへ戻る'
              src="/static/images/ico_return.png" />
          </span>
          チュートリアルトップへ戻る
        </button>
      </div>

    </Layout >
  );
}