
import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { RichEditor } from '../RichEditor/RichEditor'
import { Exam } from "../../react-app-env";
import { DefaultButton , ButtonContainer, DefaultBackButton} from '../StylesUI/CommonLayouts';
import * as common from "../../common";
import { QrCode } from '../QrCode';

const useStyles = makeStyles((theme: Theme) =>
({
  startScreenRoot:{
      "& .public-DraftEditor-content":{
        margin:0
      }
  },
  mobileStartScreenRoot:{
    padding: "0 16px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      fontSize: common.FONT_SIZE.mainText,
      width: "180px",
      minWidth: "120px"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
      outline:0
    },
    "& .backBtn:hover": {
      backgroundColor: "#003f71",
      color: "white",
      outline:0
    }
  },
  examNameStyle: {
    color: "#003f71",
    fontSize: common.FONT_SIZE.title,
    fontWeight: "bold",
    textAlign: "center"
  },
  mobileButtonContainer: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      fontSize: "12px",
      width: "120px",
      minWidth: "100px"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
      outline:0
    },
    "& .backBtn:hover": {
      backgroundColor: "#003f71",
      color: "white",
      outline:0
    }
  },
  mobileExamNameStyle: {
    color: "#003f71",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "16px"
  },
  warnText: {
    marginTop:"1rem",
    "& p":{
      margin:0,
      fontSize: "1.4rem",
      color: "#c80e49",
      textAlign:"center"
    }
  },
  mobileWarnText: {
    marginTop:"0.7rem",
    "& p":{
      margin:0,
      fontSize: "1.2rem",
      color: "#c80e49",
      textAlign:"center"
    }
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center"
  }
  
  

}));



export function StartScreen(props: { isMobile:boolean, exam:Exam ,handleStartClick: any ,returnHome:any ,mobileUrl:any}) {


    const classNames = useStyles();
    const examNameStyle = props.isMobile ? classNames.mobileExamNameStyle : classNames.examNameStyle;
    const buttonStyle = props.isMobile ? classNames.mobileButtonContainer : classNames.buttonContainer;
    const rootStyle = props.isMobile ? classNames.mobileStartScreenRoot : classNames.startScreenRoot;



    return (

        <div className={rootStyle}>

            <Box marginBottom={4}>
                <h3 className={examNameStyle}>{props.exam.name}</h3>
            </Box>
            
            <div className={classNames.centerContainer}>
              <p>モバイル端末画面を閉じてしまった際は、こちらのQRコードからアクセスして下さい。</p> 
            </div>

            <div className={classNames.centerContainer}>
              {props.mobileUrl ? <p id="qrCode"><QrCode text={props.mobileUrl} /></p> : undefined}
            </div>

            <Box marginBottom={4}>
                <RichEditor defaultValue={props.exam.startBodyHtml} readOnly={true} />
            </Box>

            <ButtonContainer className={buttonStyle}>

              <DefaultBackButton className="backBtn" style={{ marginRight: "18px" }} onClick={props.returnHome}><ArrowBackIosIcon /><span>&nbsp;戻る</span></DefaultBackButton>

              <DefaultButton  onClick={props.handleStartClick} >開始する</DefaultButton>
            
            </ButtonContainer>
 

            <div className={ props.isMobile ? classNames.mobileWarnText : classNames.warnText } hidden={ props.exam.termType === 0 }>
              <p>※まだ試験は始まっていません。</p>
              <p>「開始する」ボタンをクリックすると試験を開始します。</p>
            </div>

        </div>

    );
}