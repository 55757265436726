
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import ReactPlayer from 'react-player';
import * as common from "../../common";
import { APIArgs, APIResponse, Exam } from "../../react-app-env";
import { Layout } from "../Layout";
import { DefaultBackButton, DefaultButton } from '../StylesUI/CommonLayouts';

const useStyles = makeStyles((theme: Theme) =>
({
    root: {
        padding: "20px",
        "& h1": {

        }
    },
    videoArea: {
        width: "100%",
    },
    mainVideo: {
        margin: "0 auto",
        width: "240px",//"100%",
        height: "320px",
        position: "relative",
        "& video": {
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%",
            transform: "scaleX(-1)"
        }
    },
    personGuide: {
        width: "100%",
        maxHeight: "100%",
        display: "block",
        position: "absolute",
        bottom: "0%",
    },
    mainImage: {
        margin: "0 auto",
        width: "240px",
        height: "320px",
    },
    photoImage: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
        transform: "scaleX(-1)"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        marginTop: "10px",
        "& button": {
            width: "100px"
        }
    }
}));

enum steps {
    explain,
    taking,
    confirm
}

/**
 * 写真撮影画面
 */
export function MobileTakePhoto() {
    const {
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();

    // スタイル
    const classNames = useStyles();

    // 動画ストリームを格納するState
    const [stateStream, setStateStream] = React.useState<MediaStream>();

    // 写真画像を格納するState
    const [imageFile, setImageFile] = React.useState<File>();

    // 現在のステップを格納するState
    const [currentStep, setCurrentStep] = React.useState(steps.explain);

    const VIDEO_SELECTOR = "." + classNames.root + " video";

    /**
     * 試験情報取得
     * @returns 
     */
    async function getExam() {
        return api<any, APIResponse<Exam>>("/api/l-exam", "GET")
    }

    /**
    * APIに画像を送信
    * @param blob 
    * @returns 
    */
    async function postPhoto(blob: Blob) {
        return await api<APIArgs, APIResponse<any>>(
            "/api/l-picture",
            "POST",
            { "pictureData": blob });
    }

    // 初期処理
    React.useEffect(() => {
        getUserMedia().then((s) => {
            setStateStream(s);
        }).catch((reason) => {
            console.error(reason);
        });
        return () => {
            if (stateStream) {
                const tracks = stateStream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
            }
            const video = document.querySelector(
                VIDEO_SELECTOR) as HTMLVideoElement;
            if (video) {
                video.src = "";
                video.srcObject = null;
            }
        };
    }, [setStateStream]);

    // 有効な機能
    const exts_on = React.useRef<string[]>([]);
    React.useEffect(() => {
        getExam().then((res) => {
            exts_on.current = res.value.extensions
                .filter(x => x.parameter == 1)
                .map(x => x.code);
        }).catch((err) => {

        });
    }, []);

    return (
        <Layout viewType="mobile">
            <div className={classNames.root}>
                <div hidden={currentStep !== steps.explain}>
                    <h1>
                        <p>本人確認のため、写真撮影を行います。</p>
                        <p>
                            撮影の準備ができたら「次へ」で開始してください。
                        </p>
                    </h1>

                    <div className={classNames.buttonContainer}>
                        {/* 本人写真撮影せずに戻ることはないので、「戻る」ボタン削除
                            <DefaultBackButton onClick={() => {
                            go("/home");
                        }}>
                            戻る
                        </DefaultBackButton>*/}
                        <DefaultButton onClick={() => {
                            setCurrentStep(steps.taking);
                        }} >
                            次へ
                        </DefaultButton>
                    </div>
                </div>
                <div hidden={currentStep !== steps.taking}>
                    <h1>
                        <p>
                            画面に顔と上半身が写っていることを確認して、
                            「撮影」ボタンで写真を撮影します。
                        </p>
                    </h1>
                    <div className={classNames.videoArea}>
                    <div className={classNames.mainVideo}>
                        <ReactPlayer
                            url={stateStream}
                            id="MainPlay"
                            playing
                            muted
                            playsinline
                            controls={false}
                                height="100%"//"auto"
                                width="auto"//"100%"
                        />
                            <img src="static/images/personguide.png" className={classNames.personGuide} />
                    </div>
                    </div>
                    <div className={classNames.buttonContainer}>
                        <DefaultBackButton onClick={() => {
                            setCurrentStep(steps.explain);
                        }}>
                            戻る
                        </DefaultBackButton>
                        <DefaultButton onClick={async () => {
                            const video = document.querySelector(
                                VIDEO_SELECTOR) as HTMLVideoElement;
                            if (!video) {
                                return;
                            }
                            const file = await videoToImageFile(
                                video
                            );
                            setImageFile(file);
                            setCurrentStep(steps.confirm);
                        }} >
                            撮影
                        </DefaultButton>
                    </div>

                </div>
                <div hidden={currentStep !== steps.confirm}>
                    <h1>
                        <p>この写真を送信してよろしいですか？</p>
                        <p>
                            よろしければ「送信」でデータを送信してください。
                        </p>
                    </h1>
                    <div className={classNames.videoArea}>
                        <div className={classNames.mainImage}>
                        <img
                            className={classNames.photoImage}
                            ref={(img) => {
                                if (!img) {
                                    return;
                                }
                                if (imageFile) {
                                    img.src = URL.createObjectURL(imageFile);
                                } else {
                                    img.src = "";
                                }
                            }}
                        />
                    </div>
                    </div>
                    <div className={classNames.buttonContainer}>
                        <DefaultBackButton onClick={() => {
                            setCurrentStep(steps.taking);
                        }}>
                            戻る
                        </DefaultBackButton>
                        <DefaultButton onClick={async () => {
                            if (!imageFile) {
                                return;
                            }
                            // apiにPOSTを実行
                            const res = await postPhoto(imageFile);
                            if (res.errorCode === 20000) {
                                if (window.confirm("本人写真を送信しました。次のステップに進みますか？　撮り直す場合はキャンセルを押してください。")) {

                                    if (exts_on.current.includes(common.ExtCode.env_record)) {
                                        go("/mobile/env-movie");
                                    } else {
                                        go("/");
                                    }

                                } else {
                                    setCurrentStep(steps.taking);
                                }
                            } else {
                                console.error(res);
                            }
                        }} >
                            送信
                        </DefaultButton>
                    </div>
                </div>
            </div>
        </Layout>
    );
}


/**
 * カメラストリーム取得をPromiseでラップする関数
 * @returns 
 */
async function getUserMedia() {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            // インカム限定
            video: {
                facingMode: { ideal: "user" },
            }
        });
        if (!stream) {
            throw new Error();
        }
        return stream;
    } catch (err) {
        console.error(err);
        throw new Error("インカメラでの動画撮影が開始できませんでした。");
    }
}

/**
     * videoタグからメモリー上の画像ファイルを作成する関数
     * @returns 
     */
async function videoToImageFile(video: HTMLVideoElement) {
    const canvas = document.createElement("canvas");
    canvas.width = video.clientWidth;
    canvas.height = video.clientHeight;
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(video, 0, 0,
        canvas.width,
        canvas.height);
    // canvasの表示からファイルを生成
    const file = await canvasToImageFile(canvas);
    return file;
}

/**
 * canvasからメモリー上のファイルを生成する関数
 * @param canvas 
 * @returns 
 */
async function canvasToImageFile(canvas: HTMLCanvasElement) {
    return await new Promise<File | undefined>((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                // BlobからFileを生成
                const file = new File([blob],
                    Date.now().toString() + ".png",
                    { type: blob.type });
                resolve(file);
            } else {
                resolve(undefined);
            }
        }, "image/png");
    });
}