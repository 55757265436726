
import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { RichEditor } from '../../RichEditor/RichEditor'
import { Exam, hiddenFlags } from "../../../react-app-env";
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../../StylesUI/CommonLayouts';
import * as common from "../../../common";


const useStyles = makeStyles((theme: Theme) =>
({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      fontSize: common.FONT_SIZE.mainText,
      width: "240px",
      minWidth: "120px",
      marginLeft: "24px"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
      outline:0
    },
    "& .backBtn:hover": {
      backgroundColor: "#003f71",
      color: "white",
      outline:0
    },
    "& span": {
      paddingBottom: "2px"
    }
  },
  examNameStyle: {
    color: "#003f71",
    fontSize: common.FONT_SIZE.title,
    fontWeight: "bold",
    textAlign: "center"
  },
  warningText:{
    fontWeight: "bold",
    color:"red",
    fontSize:"2rem",
    margin: "0 auto",
    textAlign: "center"
  }

}));



export function EndScreen(props: { isTested: boolean, isFinishedTest: boolean, exam: Exam, hiddenFlags: hiddenFlags, handleReturnClick: any, handleEndClickOnEndScreen: any, handleEndClickAfterTested: any, handleEndByViewDevice: any }) {

  //-----スタイルの宣言-------
  const classNames = useStyles();


  const endProcess = props.isTested ? props.handleEndClickAfterTested : (props.exam.choiceDeviceFlag ? props.handleEndByViewDevice : props.handleEndClickOnEndScreen)

  return (

    <div>

      <Box marginBottom={4}>
        <h3 className={classNames.examNameStyle}>{props.exam.name}&nbsp;終了確認</h3>
      </Box>

      <Box marginBottom={4}>
        <RichEditor defaultValue={props.exam.endBodyHtml} readOnly={true} />
      </Box>

      <Box marginBottom={4}>
        <p className={classNames.warningText}>※一度解答を終了すると再解答は出来ません</p>
      </Box>

      <ButtonContainer className={classNames.buttonContainer}>
        <DefaultBackButton className="backBtn" hidden={props.hiddenFlags.return || props.isFinishedTest} style={{ marginRight: "8px" }} onClick={props.handleReturnClick}><ArrowBackIosIcon /><span>&nbsp;解答へ戻る</span></DefaultBackButton>

        {/*<DefaultButton onClick={endProcess} hidden={props.exam.choiceDeviceFlag} ><CheckCircleOutlineIcon /><span>&nbsp;{props.exam.choiceDeviceFlag ? "問題の表示を終了する" : "解答を終了する"}</span></DefaultButton>*/}
        <DefaultButton onClick={endProcess} hidden={props.exam.choiceDeviceFlag} ><CheckCircleOutlineIcon /><span>&nbsp;試験を終了する</span></DefaultButton>
      </ButtonContainer>

    </div>

  );
}